<template>
  <v-form>
    <v-row>
      <v-col md="5">
        <v-text-field
          v-model="organization.name"
          label="Nombre*"
          required
          :error-messages="this.errors['name']"
        ></v-text-field>
      </v-col>
      <v-col md="5">
        <v-text-field
          label="Descripción*"
          v-model="organization.description"
        ></v-text-field>
      </v-col>
      <v-col md="2">
        <v-btn
          color="green"
          fab
          x-small
          dark
          class="mt-2 mr-2"
          @click="handleSubmit"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import organizations from "@/services/organizations";

export default {
  name: "organizationForm",

  data: () => ({
    organization: {
      name: null,
      description: null
    },
    errors: {}
  }),
  methods: {
    handleSubmit() {
      let self = this;
      organizations
        .create(this.organization)
        .then(response => {
          console.log(response);
          this.$emit("organizationSuccess", true, response.data);
        })
        .catch(error => {
          if (error.response) {
            self.errors = error.response.data;
          }
        });
    }
  }
};
</script>
