<template>
  <v-alert v-if="errors" type="error" class="mt-5" dismissible>
    <ul>
      <li v-for="fullMessage in fullMessages" :key="fullMessage">{{fullMessage}}</li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  props: ["errors"],
  computed: {
    fullMessages() {
      let list = [];

      if (this.errors["details"]) {
        Object.entries(this.errors["details"]).map(detail => {
          detail[1]["full_messages"].forEach(message => {
            list.push(message);
          });
        });
        return list;
      } else {
        return null;
      }
    }
  }
};
</script>