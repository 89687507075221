<template>
  <v-card class="mx-auto" flat>
    <errorAlert
      :errors="this.errors"
      v-if="this.errors['details']"
    ></errorAlert>
    <v-text-field
      v-model="election.name"
      label="Nombre de la elección"
      :error-messages="errorFor('name')"
      @blur="update"
      :disabled="!canEdit"
    ></v-text-field>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="election.organization"
          :items="organizations"
          item-text="name"
          item-value="id"
          return-object
          ref="selOrganization"
          label="Entidad organizadora"
          @change="update"
          :disabled="!canEdit"
        >
          <template v-slot:prepend-item>
            <div v-if="overlay == true">
              <v-card flat>
                <v-card-title class="pa-0">
                  <v-btn
                    color="red"
                    block
                    text
                    tile
                    dark
                    @click="overlay = false"
                  >
                    cancelar
                  </v-btn>
                </v-card-title>
                <v-divider color="red"></v-divider>
                <v-card-text class="py-0">
                  <organizationForm
                    @organizationSuccess="updateOrganizations"
                  ></organizationForm>
                </v-card-text>
              </v-card>
            </div>
            <div v-if="overlay == false">
              <v-btn color="red" text block @click="overlay = true">
                <v-icon left>
                  mdi-plus
                </v-icon>
                agregar organización
              </v-btn>
            </div>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:item="{ item }">
            <div class="v-list-item__title">
              <strong>{{ item.name }}</strong>
              <br />
              {{ item.description }}
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-row justify="center" align="center">
          <v-col cols="12">
            {{ election.organization.description }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="election.country_alpha2"
          :items="countries"
          item-text="name"
          item-value="id"
          label="País"
          :error-messages="errorFor('country_alpha2')"
          :disabled="!canEdit"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="election.timezone"
          :items="timezones"
          item-text="name"
          item-value="id"
          label="Zona horaria"
          :error-messages="errorFor('timezone')"
          :disabled="!canEdit"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <v-dialog v-model="modalStartDate" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDateFormatted"
              label="Fecha de inicio"
              readonly
              v-bind="attrs"
              :error-messages="errorFor('start_at')"
              v-on="on"
              :disabled="!canEdit"
            ></v-text-field>
          </template>
          <v-date-picker v-model="startDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalStartDate = false"
              >Cancel</v-btn
            >
            <v-btn v-if="election.id" text color="primary" @click="update"
              >OK</v-btn
            >
            <v-btn v-else text color="primary" @click="formatDateTimes"
              >OK</v-btn
            >
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="3">
        <v-dialog v-model="modalStartHour" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startHour"
              label="Hora de inicio"
              readonly
              v-bind="attrs"
              v-on="on"
              :disabled="!canEdit"
            ></v-text-field>
          </template>
          <v-time-picker v-model="startHour" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalStartHour = false"
              >Cancel</v-btn
            >
            <v-btn v-if="election.id" text color="primary" @click="update"
              >OK</v-btn
            >
            <v-btn v-else text color="primary" @click="formatDateTimes"
              >OK</v-btn
            >
          </v-time-picker>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="3">
        <v-dialog v-model="modalEndDate" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDateFormatted"
              label="Fecha de término"
              readonly
              v-bind="attrs"
              :error-messages="errorFor('end_at')"
              v-on="on"
              :disabled="!canEdit"
            ></v-text-field>
          </template>
          <v-date-picker v-model="endDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalEndDate = false"
              >Cancel</v-btn
            >
            <v-btn v-if="election.id" text color="primary" @click="update"
              >OK</v-btn
            >
            <v-btn v-else text color="primary" @click="formatDateTimes"
              >OK</v-btn
            >
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="3">
        <v-dialog v-model="modalEndHour" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endHour"
              label="Hora de término"
              readonly
              v-bind="attrs"
              v-on="on"
              :disabled="!canEdit"
            ></v-text-field>
          </template>
          <v-time-picker v-model="endHour" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalEndHour = false"
              >Cancel</v-btn
            >
            <v-btn v-if="election.id" text color="primary" @click="update"
              >OK</v-btn
            >
            <v-btn v-else text color="primary" @click="formatDateTimes"
              >OK</v-btn
            >
          </v-time-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="election.id"
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute(election.id, 'questions')"
      >
        Siguiente
        <v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
      <v-btn
        v-else
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        @click.prevent="create"
      >
        Registrar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import errorAlert from "@/components/alert/error";
import elections from "@/services/elections";
import countries from "@/services/countries";
import timezones from "@/services/timezones";
import organizations from "@/services/organizations";
import organizationForm from "@/components/organizations/Form";
import electionCanEdit from "@/mixins/electionCanEdit";

export default {
  name: "BallotForm",
  mixins: [electionCanEdit],
  props: ["election"],
  data: () => ({
    organizations: [],
    countries: [],
    timezones: [],
    errors: {},
    overlay: false,
    modalStartDate: false,
    modalEndDate: false,
    modalStartHour: false,
    modalEndHour: false,
    startDate: null,
    endDate: null,
    startHour: null,
    endHour: null
  }),
  mounted() {
    this.setDates();
    organizations.get().then(response => {
      this.organizations = response.data;
    });
    countries.get().then(response => {
      this.countries = response.data;
    });
    timezones.get().then(response => {
      this.timezones = response.data;
    });
  },
  watch: {
    election() {
      this.setDates();
    }
  },
  computed: {
    startDateFormatted() {
      return this.formatDate(this.startDate);
    },
    endDateFormatted() {
      return this.formatDate(this.endDate);
    }
  },
  methods: {
    update() {
      if (this.election.id) {
        let self = this;
        this.formatDateTimes();
        this.election.organization_id = this.election.organization.id;
        elections
          .update(this.election)
          .then(() => {
            self.errors = {};
          })
          .catch(error => {
            if (error.response) {
              self.errors = error.response.data;
            }
          });
      }
    },
    create() {
      let self = this;
      this.formatDateTimes();
      this.election.organization_id = this.election.organization.id;
      elections
        .create(this.election)
        .then(response => {
          self.errors = {};
          this.$router.push(this.getRoute(response.data.id, "questions"));
        })
        .catch(error => {
          if (error.response) {
            self.errors = error.response.data;
          }
        });
    },
    updateOrganizations(success, organization) {
      if (success) {
        organizations.get().then(response => {
          this.organizations = response.data;
          this.election.organization = organization;
          if (this.election.id) {
            this.update();
          }
        });
        this.overlay = false;
        this.$refs.selOrganization.isMenuActive = false;
      }
    },
    getRoute(electionId, option) {
      return "/wizard/" + electionId + "/" + option;
    },
    formatDateTimes() {
      this.election.start_at = this.startDate + " " + this.startHour;
      this.election.end_at = this.endDate + " " + this.endHour;
      this.modalEndDate = false;
      this.modalStartDate = false;
      this.modalEndHour = false;
      this.modalStartHour = false;
    },
    setDates() {
      const [start_date, start_time] = this.election.start_at.split(" ");
      const [end_date, end_time] = this.election.end_at.split(" ");
      const [start_time_hour, start_time_minutes] = start_time.split(":");
      const [end_time_hour, end_time_minutes] = end_time.split(":");
      this.startDate = start_date;
      this.endDate = end_date;
      this.startHour = start_time_hour + ":" + start_time_minutes;
      this.endHour = end_time_hour + ":" + end_time_minutes;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    errorFor(attribute) {
      if (this.errors["details"] != undefined) {
        if (this.errors["details"][attribute] != undefined) {
          return this.errors["details"][attribute]["errors"];
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  },
  components: {
    organizationForm,
    errorAlert
  }
};
</script>