var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[(this.errors['details'])?_c('errorAlert',{attrs:{"errors":this.errors}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Nombre de la elección","error-messages":_vm.errorFor('name'),"disabled":!_vm.canEdit},on:{"blur":_vm.update},model:{value:(_vm.election.name),callback:function ($$v) {_vm.$set(_vm.election, "name", $$v)},expression:"election.name"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{ref:"selOrganization",attrs:{"items":_vm.organizations,"item-text":"name","item-value":"id","return-object":"","label":"Entidad organizadora","disabled":!_vm.canEdit},on:{"change":_vm.update},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [(_vm.overlay == true)?_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-btn',{attrs:{"color":"red","block":"","text":"","tile":"","dark":""},on:{"click":function($event){_vm.overlay = false}}},[_vm._v(" cancelar ")])],1),_c('v-divider',{attrs:{"color":"red"}}),_c('v-card-text',{staticClass:"py-0"},[_c('organizationForm',{on:{"organizationSuccess":_vm.updateOrganizations}})],1)],1)],1):_vm._e(),(_vm.overlay == false)?_c('div',[_c('v-btn',{attrs:{"color":"red","text":"","block":""},on:{"click":function($event){_vm.overlay = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" agregar organización ")],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"v-list-item__title"},[_c('strong',[_vm._v(_vm._s(item.name))]),_c('br'),_vm._v(" "+_vm._s(item.description)+" ")])]}}]),model:{value:(_vm.election.organization),callback:function ($$v) {_vm.$set(_vm.election, "organization", $$v)},expression:"election.organization"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.election.organization.description)+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.countries,"item-text":"name","item-value":"id","label":"País","error-messages":_vm.errorFor('country_alpha2'),"disabled":!_vm.canEdit},model:{value:(_vm.election.country_alpha2),callback:function ($$v) {_vm.$set(_vm.election, "country_alpha2", $$v)},expression:"election.country_alpha2"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.timezones,"item-text":"name","item-value":"id","label":"Zona horaria","error-messages":_vm.errorFor('timezone'),"disabled":!_vm.canEdit},model:{value:(_vm.election.timezone),callback:function ($$v) {_vm.$set(_vm.election, "timezone", $$v)},expression:"election.timezone"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de inicio","readonly":"","error-messages":_vm.errorFor('start_at'),"disabled":!_vm.canEdit},model:{value:(_vm.startDateFormatted),callback:function ($$v) {_vm.startDateFormatted=$$v},expression:"startDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalStartDate),callback:function ($$v) {_vm.modalStartDate=$$v},expression:"modalStartDate"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalStartDate = false}}},[_vm._v("Cancel")]),(_vm.election.id)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.update}},[_vm._v("OK")]):_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.formatDateTimes}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora de inicio","readonly":"","disabled":!_vm.canEdit},model:{value:(_vm.startHour),callback:function ($$v) {_vm.startHour=$$v},expression:"startHour"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalStartHour),callback:function ($$v) {_vm.modalStartHour=$$v},expression:"modalStartHour"}},[_c('v-time-picker',{attrs:{"scrollable":""},model:{value:(_vm.startHour),callback:function ($$v) {_vm.startHour=$$v},expression:"startHour"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalStartHour = false}}},[_vm._v("Cancel")]),(_vm.election.id)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.update}},[_vm._v("OK")]):_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.formatDateTimes}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de término","readonly":"","error-messages":_vm.errorFor('end_at'),"disabled":!_vm.canEdit},model:{value:(_vm.endDateFormatted),callback:function ($$v) {_vm.endDateFormatted=$$v},expression:"endDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalEndDate),callback:function ($$v) {_vm.modalEndDate=$$v},expression:"modalEndDate"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalEndDate = false}}},[_vm._v("Cancel")]),(_vm.election.id)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.update}},[_vm._v("OK")]):_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.formatDateTimes}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-dialog',{attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora de término","readonly":"","disabled":!_vm.canEdit},model:{value:(_vm.endHour),callback:function ($$v) {_vm.endHour=$$v},expression:"endHour"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalEndHour),callback:function ($$v) {_vm.modalEndHour=$$v},expression:"modalEndHour"}},[_c('v-time-picker',{attrs:{"scrollable":""},model:{value:(_vm.endHour),callback:function ($$v) {_vm.endHour=$$v},expression:"endHour"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalEndHour = false}}},[_vm._v("Cancel")]),(_vm.election.id)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.update}},[_vm._v("OK")]):_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.formatDateTimes}},[_vm._v("OK")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.election.id)?_c('v-btn',{staticClass:"mx-auto mt-5",attrs:{"color":"red","width":"40%","rounded":"","dark":"","to":_vm.getRoute(_vm.election.id, 'questions')}},[_vm._v(" Siguiente "),_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1):_c('v-btn',{staticClass:"mx-auto mt-5",attrs:{"color":"red","width":"40%","rounded":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.create($event)}}},[_vm._v(" Registrar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }