<template>
  <div class="edit-election">
    <wizard />
    <h2>{{ election.name }}</h2>
    <electionForm :election="election" />
  </div>
</template>

<script>
import loadElection from "@/mixins/loadElection";

import wizard from "@/components/wizard/wizard.vue";
import electionForm from "@/components/ballots/BallotForm.vue";

export default {
  mixins: [loadElection],
  components: {
    wizard,
    electionForm
  }
};
</script>