import axios from "axios";
import authHeader from "./authHeader";

const API = process.env.VUE_APP_API_URL + "/organizations";

export default {
  get() {
    return axios.get(API, {
      headers: authHeader()
    });
  },
  create(organization) {
    return axios.post(API,
      { "organization": organization },
      {
        headers: authHeader()
      }
    )
  }
};
